import { useModelActions } from '@rexlabs/model-generator';
import uploads from 'data/models/entities/uploads';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { sumBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface AddDocumentPayload {
  name: string;
  uri: string;
  url: string;
}

interface Props {
  addDocuments: (documents: AddDocumentPayload[]) => void;
  canAdd: boolean;
  maxFileSize?: number;
}

// 2mb, same as r2.u.app.upload.js
const DEFAULT_MAX_FILE_SIZE = 1024 * 1024 * 2;

const useDropFiles = ({
  addDocuments,
  canAdd,
  maxFileSize = DEFAULT_MAX_FILE_SIZE
}: Props) => {
  const dropZone = useRef<HTMLDivElement>(null);
  const errorDialog = useErrorDialog();
  const { uploadFile } = useModelActions(uploads);
  const uploadedFiles = useRef(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [progressText, setProgressText] = useState('');

  const uploadFiles = useCallback(
    async (files: FileList | null) => {
      if (!files) return;

      setIsDragging(true);
      setIsUploading(true);

      const filesArray = Array.from(files);
      const totalSize = sumBy(files, 'size');

      const promises: Array<Promise<AddDocumentPayload | string>> =
        filesArray.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);

          if (file.size > maxFileSize) {
            return `${file.name}: ${(file.size * 0.000001).toFixed(
              2
            )} MB is too large and will not be uploaded. Max file size is ${(
              maxFileSize * 0.000001
            ).toFixed(2)} MB`;
          }

          try {
            const res = await uploadFile({
              formData,
              onUploadProgress: (e: { loaded: number; total: number }) => {
                const progress =
                  (100 / files.length) * uploadedFiles.current +
                  ((e.loaded / e.total) * 100) / files.length;
                setProgressText(
                  `Uploading ${uploadedFiles.current + 1} of ${
                    files.length
                  } (${(totalSize / 1000).toFixed(0)} kb), ${progress.toFixed(
                    0
                  )}%`
                );
                if (e.loaded / e.total === 1) {
                  uploadedFiles.current += 1;
                }
              }
            });
            const uploadedFile = res.data.result;
            return {
              name: file.name,
              uri: uploadedFile.uri,
              url: uploadedFile.url
            } as AddDocumentPayload;
          } catch (error) {
            return `${file.name}: ${(error as Error).message}`;
          }
        });

      const res = await Promise.all(promises);
      const errors = res.filter((data) => typeof data === 'string');

      if (errors.length > 0) {
        errorDialog.open({ errors });
      }

      addDocuments(
        res.filter((data) => typeof data !== 'string') as AddDocumentPayload[]
      );

      setIsDragging(false);
      uploadedFiles.current = 0;
      setProgressText('');
      setIsUploading(false);
    },
    [addDocuments, errorDialog, uploadFile, maxFileSize]
  );

  useEffect(() => {
    if (!canAdd) return;

    if (!dropZone.current) return;

    const dropZoneRef = dropZone.current;

    const highlight = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(true);
    };

    const removeHighlight = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);
    };

    const onDrop = async (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer?.files; // Get the dropped files

      if (!files || files.length === 0) {
        setIsDragging(false);
        return;
      }

      await uploadFiles(files);
    };

    // Highlight the drop zone when dragging files
    dropZoneRef.addEventListener('dragenter', highlight);
    dropZoneRef.addEventListener('dragover', highlight);

    // Remove highlight when the drag leaves the zone
    dropZoneRef.addEventListener('dragleave', removeHighlight);

    // Handle file drop
    dropZoneRef.addEventListener('drop', onDrop);

    return () => {
      dropZoneRef.removeEventListener('dragenter', highlight);
      dropZoneRef.removeEventListener('dragover', highlight);
      dropZoneRef.removeEventListener('dragleave', removeHighlight);
      dropZoneRef.removeEventListener('drop', onDrop);
    };
  }, [uploadFiles, canAdd]);

  return { isUploading, progressText, isDragging, dropZone, uploadFiles };
};

export default useDropFiles;
