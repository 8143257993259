import 'utils/globals';

import React from 'react';
import { Provider } from 'react-redux';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { PortalProvider } from '@rexlabs/portal';
import { store } from 'src/store';
import { TEXTS, ZINDEX } from 'theme';
import { createBridgeProxy } from 'utils/bridge-proxy';

import CollisionAvoidanceBar from 'view/components/collision-avoidance-bar';
import {
  AddressSelect,
  EntitySelect,
  Select,
  TimeSelect,
  ValueListSelect
} from 'view/components/input/select';
import WorkflowNotificationToast from 'view/components/toast/workflow';
import PostcodeUploadComponent from 'view/components/shared/paf-upload';
import ClickToDialScreen from 'view/screens/apps/click-to-dial';
import {
  AppointmentsWidget,
  VerificationsWidget
} from 'view/components/widgets';
import FileUploadInput from 'view/components/custom-fields/file-upload';
import UrlInput from 'view/components/input/url';
import MatchProfileSearch from 'view/components/input/select/match-profile';
import Paywall from 'view/components/paywall';
import { RecordSummaryStats } from 'components/record-summary-stats';

import { ICONS } from 'shared/components/icon';
import WorkflowWidget from 'view/components/widgets/workflow';
import SpokeWidget from 'view/components/widgets/spoke';
import DelegateInput from 'view/forms/workflow/delegate-input';
import CustomFieldsRecordSelect from 'view/components/custom-fields/record-select';
import MailMergeRecordSelect from 'view/components/mail-merge/record-select';
import PrivacyWidget from 'view/components/widgets/privacy';
import PrivacyBanner from 'view/components/privacy/banner';
import BillingPortalRedirect from 'view/components/billing-portal-redirect';
import { PopoutProvider } from 'view/context/popout';
import UpgradeTooltip from 'view/components/tooltip/upgrade';
import List from 'view/components/classic-styled/list';
import { EmbeddedAppFrame } from 'components/embedded-app-frame';
import TagsWidget from 'src/components/right-bar/tags-widget';
import { AdminRecordViewPaywallBanner } from 'src/view/components/paywall-banner';
import { LeadsAutoResponderTemplateSelector } from 'features/listings/components/leads-auto-responder-template-selector';
import { ContactPreviewPopoutCore } from 'features/contacts/popouts/contact-preview-popout';
import { MarketLeadPreviewPopoutCore } from 'features/market-leads/popouts/market-lead-preview-popout';
import { ListingPreviewPopoutCore } from 'features/listings/popouts/listing-preview-popout';
import { PropertyPreviewPopoutCore } from 'features/properties/popouts/property-preview-popout';
import { MatchProfileSettings } from 'features/settings/screens/match-profile-settings';
import { LeadsDialogLeftHandPane } from 'features/leads/components/leads-dialog-left-hand-pane';
import { ArchivedMatchProfiles } from 'features/contacts/match-profile/archived-match-profile';
import { SingleViewConnections } from 'features/settings/screens/single-view-connections';
import ContractConditions from 'features/settings/screens/contract-conditions';
import ReinzCustomValues from 'features/apps/reinz/screens/custom-values/';
import ReinzForms from 'features/apps/reinz/screens/reinz-forms/';
import AdminChartOfAccounts from 'features/finance/screens/admin-chart-of-accounts';
import AdminAccountDefaults from 'features/finance/screens/admin-account-defaults';
import SyncInvoices from 'features/apps/xero/screens/sync-invoices';
import InvoiceSettings from 'features/apps/xero/screens/invoice-settings';
import SlidingScaleSettingsScreen from 'features/settings/screens/sliding-scale-settings/sliding-scale-settings-screen';
// NOTE: this needs to be imported after all components to make sure styles
// are being applied in the right order!
import ShellStylesProvider from 'components/shell-styles-provider';
import ApplicationsTab from 'features/tenancy-applications/screens/applications-tab';
import HandoffWidget from 'components/right-bar/handoff-widget';
import { Toggle } from 'components/inputs/toggle';
import PipelineStagesSettings from 'features/pipelines/screens/pipeline-stages-settings';
import AlertBanner from 'view/components/alert-banner';
import TrackReminders from 'features/tracks/components/track-reminders';
import DownloadGeneratedInvoices from 'features/generate-invoice/components/download-generated-invoices';
import LockWorksheetFooter from 'features/commission-worksheet/components/lock-worksheet-footer';
import { AiFilterPopout } from 'features/ai/components/generate-filter-popout/generate-ai-filter-popout';
import { AiTextGenerationButton } from 'features/ai/components/text-generation/text-generation-button';
import AgentCommission from 'features/commission-worksheet/components/agent-commission';
import ListingDocumentsTable from 'features/listings/components/documents-table/documents-table';
import MailMergeDocumentsTable from 'features/listings/components/documents-table/mail-merge-documents-table';
import { AiFiltersPromoBanner } from 'features/ai/components/ai-filters-promo-banner';
import ListingChattels from 'features/listings/components/listing-chattels';
import NewsletterBuilder from 'features/newsletter-builder/newsletter-builder';
import AdminNewsletterTemplates from 'features/newsletter-builder/screens/admin-newsletter-templates';
import WorksheetSettings from 'features/finance/screens/worksheet-settings';
import PortalStats from 'features/portal-stats';
import ListingBuyersTab from 'features/buyer-mode/screens/listing-buyers-tab/listing-buyers-tab';
import { createSystem, EnvironmentProvider } from '@chakra-ui/react';
import { shellChakraConfig } from 'src/lib/chakra/config';
import { ChakraProvider } from 'src/lib/chakra/chakra-provider';
import { CacheProvider } from '@emotion/react';
import { chakraCache } from 'src/lib/chakra/chakra-cache';
import DayMonthInput from 'view/components/input/day-month';
import MapState from 'features/properties/components/map-state';
import DocumentsTable from 'components/documents-table/documents-table';
import ListingContractConditions from 'features/listings/components/listing-contract-conditions';
import { SecuritySettings } from 'features/settings/screens/security-settings';

const classicOverrides = {
  TextInput: StyleSheet({
    wrapper: {
      minHeight: '28px'
    },

    container: {
      padding: '0 !important',
      borderRadius: '0 !important'
    },

    cosmeticWrapper: {
      padding: '0 !important',
      minHeight: '0 !important'
    },

    cosmetic: {
      paddingLeft: '0 !important',
      overflow: 'hidden !important'
    },

    input: {
      padding: '3px 0px 3px 5px !important',
      minWidth: '20px !important'
    }
  }),

  SelectOption: StyleSheet({
    container: {
      padding: '3px 0px 3px 5px !important'
    }
  }),

  FileUploadInput: StyleSheet({
    container: {
      height: '28px !important',

      '> div p': {
        lineHeight: '17px !important',
        fontSize: '14px !important',
        fontWeight: '400 !important'
      },

      '& button': {
        height: '28px !important',
        borderRadius: '0 !important'
      }
    },

    noFileLabel: {
      paddingLeft: '5px !important'
    }
  }),

  InputTag: StyleSheet({
    item: {
      width: 'calc(100% - 4px) !important',
      height: '20px !important',
      margin: '1px 2px 2px 2px !important'
    },
    multi: {
      maxWidth: 'initial'
    },
    label: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      height: 18,
      width: 18
    }
  }),

  UrlInput: StyleSheet({
    protocol: {
      width: '80px'
    }
  }),

  DefaultValue: StyleSheet({
    container: {
      minHeight: '20px !important',
      margin: '0 !important'
    },

    tooltipWrapper: {
      display: 'flex',
      alignItems: 'center'
    }
  }),

  Tether: StyleSheet({
    wrapContent: {
      zIndex: ZINDEX.POPOUT
    }
  })
};

function NoOp({ children }) {
  return <>{children}</>;
}

const system = createSystem({
  ...shellChakraConfig,
  // Needed to avoid resets not working correctly
  // mot great to disable this
  disableLayers: true
});

function WrapComponent(
  Component,
  { withTextInputFix = false, withStore = true } = {}
) {
  const StoreProvider = withStore ? Provider : NoOp;

  const TextInputFixProvider = withTextInputFix ? StylesProvider : NoOp;

  return (props) => {
    return (
      <PortalProvider>
        <StoreProvider store={store}>
          <ShellStylesProvider>
            <TextProvider text={TEXTS}>
              <PopoutProvider value={{ isClassic: true }}>
                <TextInputFixProvider components={classicOverrides}>
                  <CacheProvider value={chakraCache}>
                    <ChakraProvider value={system}>
                      <EnvironmentProvider
                        value={() => window.Rex2FrameWindow.document}
                      >
                        <Component {...createBridgeProxy(props)} />
                      </EnvironmentProvider>
                    </ChakraProvider>
                  </CacheProvider>
                </TextInputFixProvider>
              </PopoutProvider>
            </TextProvider>
          </ShellStylesProvider>
        </StoreProvider>
      </PortalProvider>
    );
  };
}

function WrapWithClassicEnvironment(Component) {
  return WrapComponent((props) => (
    <Component environment={window.Rex2FrameWindow} {...props} />
  ));
}

const ComponentsBridge = {
  Select: WrapComponent(Select),
  EntitySelect: WrapComponent(EntitySelect),
  ValueListSelect: WrapComponent(ValueListSelect),
  TimeSelect: WrapComponent(TimeSelect),
  AddressSelect: WrapComponent(AddressSelect, { withTextInputFix: true }),
  PostcodeUploadComponent: WrapComponent(PostcodeUploadComponent),
  ClickToDialScreen: WrapComponent(ClickToDialScreen),
  VerificationsWidget: WrapComponent(VerificationsWidget),
  AppointmentsWidget: WrapComponent(AppointmentsWidget),
  WorkflowWidget: WrapComponent(WorkflowWidget),
  SpokeWidget: WrapComponent(SpokeWidget),
  DelegateInput: WrapComponent(DelegateInput),
  CollisionAvoidanceBar: WrapComponent(CollisionAvoidanceBar),
  RecordSummaryStats: WrapComponent(RecordSummaryStats),
  EmbeddedAppFrame: WrapComponent(EmbeddedAppFrame),
  LeadsAutoResponderTemplateSelector: WrapComponent(
    LeadsAutoResponderTemplateSelector
  ),
  DocumentsTable: WrapComponent(DocumentsTable),

  Icons: {
    Campaign: ICONS.CAMPAIGN,
    Sparkle: ICONS.SPARKLE
  },

  CustomFields: {
    Select: WrapComponent(Select, { withTextInputFix: true }),
    TimeSelect: WrapComponent(TimeSelect, { withTextInputFix: true }),
    RecordSelect: WrapComponent(CustomFieldsRecordSelect, {
      withTextInputFix: true
    }),
    UrlInput: WrapComponent(UrlInput, { withTextInputFix: true }),
    FileUploadInput: WrapComponent(FileUploadInput, { withTextInputFix: true }),
    DayMonthInput: WrapComponent(DayMonthInput, { withTextInputFix: true })
  },

  RightBar: {
    HandoffWidget: WrapComponent(HandoffWidget),
    TagsWidget: WrapComponent(TagsWidget)
  },

  Popouts: {
    ContactPreviewPopout: WrapComponent(ContactPreviewPopoutCore),
    ListingPreviewPopout: WrapComponent(ListingPreviewPopoutCore),
    PropertyPreviewPopout: WrapComponent(PropertyPreviewPopoutCore),
    MarketLeadPreviewPopout: WrapComponent(MarketLeadPreviewPopoutCore),
    GenerateAIFilterPopout: WrapComponent(AiFilterPopout)
  },

  MatchProfiles: {
    MatchProfileSearch: WrapWithClassicEnvironment(MatchProfileSearch),
    ArchivedMatchProfiles: WrapComponent(ArchivedMatchProfiles)
  },

  MailMerge: {
    RecordSelect: WrapComponent(MailMergeRecordSelect, {
      withTextInputFix: true
    })
  },
  PrivacyWidget: WrapComponent(PrivacyWidget),
  PrivacyBanner: WrapComponent(PrivacyBanner),
  AlertBanner: WrapComponent(AlertBanner),
  WorkflowNotificationToast: WrapComponent(WorkflowNotificationToast),
  Paywall: WrapComponent(Paywall),
  BillingPortalRedirect: WrapComponent(BillingPortalRedirect),
  UpgradeTooltip: WrapComponent(UpgradeTooltip),
  List: WrapComponent(List),
  AdminRecordViewPaywallBanner: WrapComponent(AdminRecordViewPaywallBanner),

  LeadsDialogLeftHandPane: WrapComponent(LeadsDialogLeftHandPane),
  SettingsScreens: {
    BuyerMatchSettings: WrapComponent(MatchProfileSettings),
    SecuritySettings: WrapComponent(SecuritySettings),
    SingleViewConnections: WrapComponent(SingleViewConnections),
    ContractConditions: WrapComponent(ContractConditions),
    PipelineStagesSettings: WrapComponent(PipelineStagesSettings),
    SlidingScaleSettings: WrapComponent(SlidingScaleSettingsScreen)
  },
  ApplicationsTab: WrapComponent(ApplicationsTab),
  Inputs: {
    Toggle: WrapComponent(Toggle),
    ListingChattels: WrapComponent(ListingChattels)
  },
  Apps: {
    Reinz: {
      CustomValues: WrapComponent(ReinzCustomValues),
      ReinzForms: WrapComponent(ReinzForms)
    },
    Xero: {
      SyncInvoices: WrapComponent(SyncInvoices),
      InvoiceSettings: WrapComponent(InvoiceSettings)
    }
  },
  Finance: {
    AdminChartOfAccounts: WrapComponent(AdminChartOfAccounts),
    AdminAccountDefaults: WrapComponent(AdminAccountDefaults),
    WorksheetSettings: WrapComponent(WorksheetSettings)
  },
  Tracks: {
    TrackReminders: WrapComponent(TrackReminders)
  },
  AgentLedgers: {
    DownloadGeneratedInvoices: WrapComponent(DownloadGeneratedInvoices)
  },
  Listing: {
    DocumentsTable: WrapComponent(ListingDocumentsTable),
    MailMergeDocumentsTable: WrapComponent(MailMergeDocumentsTable),
    CommissionWorksheet: {
      LockWorksheetFooter: WrapComponent(LockWorksheetFooter),
      AgentCommission: WrapComponent(AgentCommission)
    },
    Contract: {
      ListingContractConditions: WrapComponent(ListingContractConditions)
    }
  },
  Ai: {
    AiTextGenerationButton: WrapComponent(AiTextGenerationButton),
    AiFiltersPromoBanner: WrapComponent(AiFiltersPromoBanner)
  },
  Newsletters: {
    NewsletterBuilder: WrapComponent(NewsletterBuilder),
    AdminNewsletterTemplates: WrapComponent(AdminNewsletterTemplates)
  },
  PortalStats: WrapComponent(PortalStats),
  BuyerMode: {
    ListingBuyersTab: WrapComponent(ListingBuyersTab)
  },
  Properties: {
    MapState: WrapComponent(MapState)
  }
};

export default ComponentsBridge;
