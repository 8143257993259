import Box from '@rexlabs/box';
import { ReactForms } from '@rexlabs/form';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import adminSecurityModel from 'data/models/custom/admin-security';
import useShouldUpdateChildAccounts from 'features/settings/hooks/use-should-update-child-accounts';
import { useErrorDialog } from 'hooks/use-error-dialog';
import React, { useCallback, useEffect } from 'react';
import { Column, Grid } from 'shared/components/grid';
import Spinner from 'shared/components/spinner';
import { PADDINGS } from 'shared/theme';
import { Form, FormField } from 'view/components/form';
import Checkbox from 'view/components/input/checkbox';
import SaveCancelOverlayHandler from 'view/components/save-cancel-overlay-handler';
import AlertBanner from 'view/components/alert-banner/index';

export function SecuritySettings() {
  const errorDialog = useErrorDialog();
  const shouldUpdateChildAccounts = useShouldUpdateChildAccounts();
  const { readSettings, updateSettings } = useModelActions(adminSecurityModel);
  const { data: settings, isLoading } = useModelState(adminSecurityModel);

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      const updateChildAccounts = await shouldUpdateChildAccounts();

      if (updateChildAccounts.didCancel) {
        resetForm();
        return;
      }

      if (updateChildAccounts.result) {
        values.update_child_accounts = true;
      }

      try {
        await updateSettings(values);
        resetForm(values);
      } catch (e: any) {
        errorDialog.open(e);
        resetForm();
      }
    },
    [updateSettings, shouldUpdateChildAccounts]
  );

  useEffect(() => {
    readSettings({}).catch(errorDialog.open);
  }, []);

  if (isLoading || !settings) {
    return (
      <Box flex={1} justifyContent={'center'} alignItems={'center'}>
        <Spinner dark />
      </Box>
    );
  }

  return (
    <Box p={PADDINGS.M} spacing={PADDINGS.S}>
      <SubHeading semibold>Enforce Multi-Factor Authentication</SubHeading>
      <Body regular normal dark>
        When enabled, all users will be required to set up multi-factor
        authentication before they can access Rex.
      </Body>
      <ReactForms handleSubmit={onSubmit} initialValues={settings}>
        {({ submitForm, isDirty, resetForm, values }) => (
          <Form hasErrorPadding>
            <Grid>
              <Column width={8}>
                <FormField
                  name='mandatory_multi_factor_authentication'
                  Input={Checkbox}
                  inputProps={{
                    label: 'Require multi-factor authentication for all users'
                  }}
                />
                {values.mandatory_multi_factor_authentication &&
                !settings.mandatory_multi_factor_authentication ? (
                  <AlertBanner
                    message='Enabling this will log out everyone who has not set up multi-factor authentication yet.'
                    style={{ m: '0 0 15px' }}
                  ></AlertBanner>
                ) : null}
              </Column>
            </Grid>
            <SaveCancelOverlayHandler
              isVisible={isDirty}
              onSave={submitForm}
              onCancel={resetForm}
            />
          </Form>
        )}
      </ReactForms>
    </Box>
  );
}
