import { useCallback, useMemo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import Box from '@rexlabs/box';
import { Column, Grid } from 'shared/components/grid';
import SortableList from 'src/view/components/sortable-dnd';
import { arrayMove } from '@dnd-kit/sortable';
import { CellActionMenu } from 'components/record-list-screen/components/cell-action-menu';
import { ListingContractConditionItem } from '../data/contracts/types';
import dayjs from 'dayjs';
import { Body } from 'components/text/body';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { ListActionMenuProps } from 'components/record-list-screen/types';
import { StateView } from 'components/record-list-screen/state-view';

const styles = StyleSheet({
  container: {
    position: 'relative',
    zIndex: 0
  },
  header: {
    backgroundColor: COLORS.PRIMARY.INFORMATIVE,
    margin: '0 !important',
    '& p': {
      color: '#fff',
      padding: '6px 0',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '12px'
    }
  },
  row: {
    alignItems: 'center',
    '& p': {
      color: COLORS.GREY_DARK
    },
    '& span': {
      fontWeight: 600
    }
  },
  ellipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  autoAdded: {
    color: `${COLORS.PRIMARY.SAND_LIGHT} !important`,
    whiteSpace: 'nowrap'
  }
});

const AUTO_CREATED_WIDTH = 74;
const CONDITION_WIDTH = 185;

interface ListingContractConditionsProps {
  conditions: ListingContractConditionItem[];
  onChange: (
    sortedConditions: Omit<ListingContractConditionItem, 'id'>[]
  ) => void;
  actionMenu:
    | ListActionMenuProps[]
    | ((row: ListingContractConditionItem) => ListActionMenuProps[]);
  disabled?: boolean;
}

function ListingContractConditions({
  conditions,
  onChange,
  actionMenu,
  disabled
}: ListingContractConditionsProps) {
  const s = useStyles(styles);
  const filteredConditions = useMemo(
    () => conditions.filter((condition) => !condition.destroy),
    [conditions]
  );
  const deletedConditions = useMemo(
    () => conditions.filter((condition) => condition.destroy),
    [conditions]
  );

  const renderItem = useCallback(
    (condition: ListingContractConditionItem, index: number) => {
      return (
        <SortableList.Item key={condition.priority} id={condition.id}>
          <Grid
            columns={4}
            p={'12px 0'}
            style={{
              backgroundColor:
                index % 2 === 0 ? 'transparent' : COLORS.BACKGROUNDS.SAND_LIGHT
            }}
            {...s('row')}
          >
            {!disabled && hasFeatureFlags('contract_conditions') && (
              <Column
                width={0.2}
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
              >
                <SortableList.DragHandle
                  tooltip={() => 'Drag and drop to reorder'}
                />
              </Column>
            )}
            <Column
              p={'0 6px 0 0'}
              width={1.3}
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='nowrap'
            >
              <Box
                flexDirection={'row'}
                alignItems={'center'}
                spacing={6}
                style={{ flex: 1 }}
              >
                <p
                  {...s('ellipsis')}
                  style={{
                    maxWidth:
                      CONDITION_WIDTH -
                      (condition.is_auto_created &&
                      hasFeatureFlags('contract_conditions')
                        ? AUTO_CREATED_WIDTH
                        : 0),
                    width: 'unset'
                  }}
                >
                  {condition.condition_type}
                </p>
                {condition.is_auto_created &&
                  hasFeatureFlags('contract_conditions') && (
                    <Body small regular normal {...s('autoAdded')}>
                      — auto added
                    </Body>
                  )}
              </Box>
              {!disabled && (
                <CellActionMenu data={condition} items={actionMenu} />
              )}
            </Column>
            <Column width={1.7}>
              <p {...s('ellipsis')}>{condition.condition_notes}</p>
            </Column>
            <Column width={0.8}>
              <p>
                {condition.condition_date_of &&
                dayjs(condition.condition_date_of).isValid()
                  ? dayjs(condition.condition_date_of).format('DD MMM YYYY')
                  : ''}
              </p>
            </Column>
          </Grid>
        </SortableList.Item>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled]
  );

  function onDragEnd(oldIndex: number, newIndex: number) {
    return onChange([
      ...arrayMove(filteredConditions, oldIndex, newIndex).map(
        (condition, index) => ({
          ...condition,
          priority: index + 1
        })
      ),
      // We still pass them for the API call
      ...deletedConditions
    ]);
  }

  return (
    <Box {...s('container')}>
      <Grid columns={4} {...s('header')}>
        {hasFeatureFlags('contract_conditions') ? (
          <>
            {!disabled && (
              <Column width={0.2}>
                <p></p>
              </Column>
            )}
            <Column p={'0 6px 0 0'} width={1.3}>
              <p>condition</p>
            </Column>
            <Column width={1.7}>
              <p>description</p>
            </Column>
            <Column width={0.8}>
              <p>due date</p>
            </Column>
          </>
        ) : (
          <>
            <Column p={'0 6px 0 0'} width={1.3}>
              <p>description</p>
            </Column>
            <Column width={1.7}>
              <p>notes</p>
            </Column>
            <Column width={0.8}>
              <p>date</p>
            </Column>
          </>
        )}
      </Grid>
      {filteredConditions.length === 0 ? (
        <StateView noPadding>No current conditions</StateView>
      ) : (
        <SortableList<ListingContractConditionItem>
          items={filteredConditions}
          onDragEnd={onDragEnd}
          renderItem={renderItem}
        />
      )}
    </Box>
  );
}

export default ListingContractConditions;
