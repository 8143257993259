export const ACCEPTED_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.jpeg',
  '.jpg',
  '.gif',
  '.png',
  '.bmp',
  '.tiff',
  '.txt',
  '.zip',
  '.rar',
  '.psd',
  '.pub',
  '.ppt',
  '.pptx',
  '.msg',
  '.rtf',
  '.csv',
  '.tif',
  '.html',
  '.htm',
  '.odt'
];
