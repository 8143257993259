import { api } from 'shared/utils/api-client/index';
import { Generator } from 'shared/utils/models';

const TYPE = 'adminSecurity';

export interface SecuritySettingsData {
  mandatory_multi_factor_authentication: boolean;
}

export interface AdminSecurityState {
  isLoading: boolean;
  data: SecuritySettingsData;
}

const actionCreators = {
  readSettings: {
    request: () =>
      api
        .post('AccountSettingsSecurity::getEffectiveSettings')
        .then((response) => response.data.result),
    reduce: {
      intial: (state) => ({ ...state, isLoading: true }),
      success: (state, action) => ({
        ...state,
        data: action.payload,
        isLoading: false
      }),
      failure: (state) => ({ ...state, isLoading: false })
    }
  },
  updateSettings: {
    request: (payload) =>
      api
        .post('AccountSettingsSecurity::updateSettings', {
          settings: payload
        })
        .then((response) => response.data.result),
    reduce: {
      intial: (state) => ({ ...state, isLoading: true }),
      success: (state, action) => ({
        ...state,
        data: action.payload,
        isLoading: false
      }),
      failure: (state) => ({ ...state, isLoading: false })
    }
  }
};

const adminSecurityModel = new Generator<AdminSecurityState>(TYPE).createModel({
  actionCreators,
  initialState: {}
});

export default adminSecurityModel;
