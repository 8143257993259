import React, { useCallback, ComponentType } from 'react';
import { compose } from 'redux';
import _ from 'lodash';

import {
  Id,
  withValueLists,
  useModelActions,
  useModelState
} from '@rexlabs/model-generator';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import accountUsersModel, {
  AccountUsersModel,
  UserItem
} from 'data/models/value-lists/account-users';
import leadsModel from 'data/models/entities/leads';
import sessionModel from 'data/models/custom/session';

import { ReactForms } from 'view/components/form';
import { withErrorDialog } from 'hocs/with-error-dialog';
import { FormContents } from 'components/forms/assignee-form-contents';
import { Link } from 'components/text/link';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';

interface LeadsDialogAssigneePopoutProps {
  leadsId: Id;
  user?: UserItem;
  valueLists: {
    accountUsers: AccountUsersModel;
  };
  errorDialog: { open: (error: Error) => void };
}

type ExternalAssigneePopoutProps = Pick<
  LeadsDialogAssigneePopoutProps,
  'user' | 'leadsId'
>;

function AssigneePopout({
  valueLists,
  user,
  leadsId,
  errorDialog
}: LeadsDialogAssigneePopoutProps) {
  const allUsers = valueLists?.accountUsers?.items;

  const { updateItem } = useModelActions(leadsModel);
  const session = useModelState(sessionModel);

  const handleSubmit = useCallback(
    async ({ assignee_user_id }) => {
      if (!assignee_user_id) return;
      return updateItem({
        id: leadsId,
        data: {
          assignee: { id: assignee_user_id }
        }
      })
        .then(() => {
          Analytics.track({
            event: EVENTS.LEADS_DIALOG.UPDATE_ASSIGNEE,
            options: { integrations: { Intercom: true } }
          });
        })
        .catch(errorDialog.open);
    },
    [updateItem, leadsId, errorDialog.open]
  );

  return (
    <ReactForms handleSubmit={handleSubmit}>
      {({ submitForm, setFieldValue, isSubmitting }) => {
        return (
          <Box flexDirection='row'>
            <FormContents
              submitForm={submitForm}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              currentUser={session.user_details.id}
              allUsers={allUsers}
              hasUser={!_.isEmpty(user)}
              initialValue={user?.id}
            />
            {session.user_details.id !== user?.id && (
              <Link
                style={{ marginLeft: PADDINGS.XXS }}
                regular
                onClick={() => {
                  handleSubmit({
                    assignee_user_id: session.user_details.id
                  });
                }}
              >
                Assign to me
              </Link>
            )}
          </Box>
        );
      }}
    </ReactForms>
  );
}

export const LeadsDialogAssigneePopout = compose<
  ComponentType<ExternalAssigneePopoutProps>
>(
  withValueLists(accountUsersModel),
  withErrorDialog
)(AssigneePopout);
