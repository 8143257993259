import _ from 'lodash';
import React, { PureComponent, createContext } from 'react';

const PopoutContext = createContext();
const { Provider: PopoutProvider, Consumer: PopoutConsumer } = PopoutContext;

/**
 * NOTE: We have a pretty big problem with this function. If the component doesn’t exist in classic, until something
 * in a classic dialog changes it. It is very common for save callbacks to be called in dialogs, and once those
 * callbacks are completed, we then close the dialog. So if the callback causes a new component with a popout attached
 * to it to appear, the popouts target will use window.parent.Rex2DialogFrameWindow.document.body - but once the dialog
 * closes, it will not have a valid target anymore.
 *
 * For example, with the leads auto responder template selector, we perform the callback to attach the template to
 * the listing, before closing the dialog. Once the template is attached (while the dialog is still open), the Attach
 * Template button conditionally changes to a split button - that is a button with an action menu - so the above
 * instance occurs.
 *
 * https://rexsoftware.atlassian.net/browse/RADI-6079
 */
const classicPopoutTarget = () =>
  window.parent.Rex2DialogFrameWindow
    ? window.parent.Rex2DialogFrameWindow.document.body
    : window.parent.Rex2FrameWindow
    ? window.parent.Rex2FrameWindow.document.body
    : window.document.body; // Fallback to current windows body element

/**
 * This HoC has been implemented to fix a bug with our Popout component and the way that it uses
 * body elements to calculate its position. If we open a Popout in Classic via the Bridge without
 * the target set to the Classic body, it will be positioned incorrectly. This HoC allows
 * passing the correct body target via Context without needing to pass the prop down a deep tree
 * of components.
 *
 * @param {*} WrappedComponent - The child that will receive the correct target to be used for
 * positioning the Popout component.
 */
const withPopoutContext = (WrappedComponent) => {
  class ContextWrapper extends PureComponent {
    constructor() {
      super();

      this.state = {
        classicDialog: null,
        mounted: false
      };
      this.popoutSibling = React.createRef(null);
    }

    componentDidMount() {
      // Check if popout is inside classic dialog
      this.setState({
        classicDialog:
          this.popoutSibling.current?.closest('.r2-dialog-wrapper'),
        mounted: true
      });
    }

    render() {
      return (
        <>
          {/* This will be used to detect if popout is inside classic dialog */}
          {/* After check we unmount the sibling so it doesn't interfere with the styles of the popout content */}
          {!this.state.mounted && (
            <span ref={this.popoutSibling} style={{ display: 'none' }} />
          )}
          <PopoutConsumer>
            {(context) => (
              <WrappedComponent
                {...this.props}
                context={
                  _.get(context, 'isClassic', false)
                    ? this.state.classicDialog || classicPopoutTarget()
                    : window.document.body
                }
              />
            )}
          </PopoutConsumer>
        </>
      );
    }
  }

  return ContextWrapper;
};

export { PopoutProvider, withPopoutContext };
