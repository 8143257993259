import { useMemo } from 'react';
import { ListingDocument } from '../../types/common';
import { ColumnConfig } from 'components/record-list-screen/types';
import sessionModel from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';
import { usePermissions } from 'hooks/use-permissions';
import {
  accessColumn,
  dateUploadedColumn,
  descriptionColumn,
  typeColumn
} from 'features/listings/components/documents-table/basic-columns';
import DocumentsTable, {
  DocumentsTableProps
} from 'components/documents-table/documents-table';

const ListingDocumentsTable = (props: DocumentsTableProps) => {
  const { actionMenu } = props;
  const session = useModelState(sessionModel);
  const { check } = usePermissions();

  const columns: ColumnConfig<ListingDocument>[] = useMemo(
    () => {
      return [
        {
          ...descriptionColumn,
          cellProps: {
            items: (row: ListingDocument) => {
              const { privacy, exclude_from_mail_merge } = row;

              // If there is no system_created_user it means it is new and the owner is the current user
              const isUserOwner = row.system_created_user
                ? row.system_created_user.id === session.user_details.id
                : true;

              const userCanSendAnyDocuments = check('documents.send_any');
              const restrictDocumentLinkSharing =
                privacy.id === 'private' && exclude_from_mail_merge;

              const hasUpdateRights = isUserOwner
                ? check('documents.update_any') ||
                  check('documents.update_created')
                : check('documents.update_any');

              return actionMenu.filter((action) => {
                // Edit & Delete actions require update privileges
                const actionRequiresUpdatePrivileges =
                  action.checkRights === '{{updateDocuments}}';

                if (actionRequiresUpdatePrivileges) return hasUpdateRights;

                const actionRequiresSharingPrivileges = [
                  'Send Link',
                  'Get Share Link'
                ].includes(action.label as string);

                if (
                  actionRequiresSharingPrivileges &&
                  restrictDocumentLinkSharing
                )
                  return userCanSendAnyDocuments;

                return true;
              });
            }
          }
        },
        typeColumn,
        accessColumn,
        { ...dateUploadedColumn, rightAlign: true }
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <DocumentsTable {...props} columns={columns} />;
};

export default ListingDocumentsTable;
