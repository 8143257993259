import React from 'react';
import PaddingBox from 'src/view/components/padding-box';
import ListingChattelField from './listing-chattel-field';
import {
  AUCTION_FIELDS,
  SALE_AND_PURCHASE_FIELDS
} from 'features/reinz-forms/constants/chattels';
import { TextArea } from '@rexlabs/text-input';
import { Body } from 'components/text/body';
import { debounce } from 'lodash';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  chattelsList: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: `repeat(10, 1fr)`,
    gap: '5px 20px',
    marginBottom: 15
  },

  otherChattelsHeading: {
    marginBottom: 15
  }
});
interface PropertyChattels {
  has_stove: boolean;
  stove_count: string;
  has_rangehood: boolean;
  rangehood_count: string;
  has_wall_oven: boolean;
  wall_oven_count: string;
  has_cooktop: boolean;
  cooktop_count: string;
  has_dishwasher: boolean;
  dishwasher_count: string;
  has_waste_disposal: boolean;
  waste_disposal_count: string;
  has_light_fittings: boolean;
  light_fittings_count: string;
  has_smoke_detectors: boolean;
  smoke_detectors_count: string;
  has_burglar_alarm: boolean;
  burglar_alarm_count: string;
  has_heated_towel_rail: boolean;
  heated_towel_rail_count: string;
  has_heat_pump: boolean;
  heat_pump_count: string;
  has_garage_door_remote: boolean;
  garage_door_remote_count: string;
  has_garden_shed: boolean;
  garden_shed_count: string;
  has_blinds: boolean;
  has_blinds_motor: boolean;
  has_curtains: boolean;
  has_curtains_motor: boolean;
  has_drapes: boolean;
  has_fixed_floor_coverings: boolean;
  has_bathroom_extractor_fan: boolean;
  id: string;
  other: string;
}

interface ListingChattelsProps {
  values: PropertyChattels;
  templateType: string;
  onChange: (name: string, value: boolean | string) => void;
}

export const ListingChattels = ({
  values,
  templateType,
  onChange
}: ListingChattelsProps) => {
  const s = useStyles(defaultStyles);

  const fields =
    templateType === 'sale_purchase'
      ? SALE_AND_PURCHASE_FIELDS
      : AUCTION_FIELDS;

  const handleCheckboxChange = (name: string, checked: boolean) => {
    onChange(`has_${name}`, checked);
    if (!checked) onChange(`${name}_count`, '');
  };

  const handleCountChange = (name: string, value: string) => {
    onChange(`${name}_count`, value);
  };

  const handleOtherChange = debounce((value: string) => {
    onChange('other', value);
  }, 200);

  return (
    <form>
      <PaddingBox>
        <div {...s('chattelsList')}>
          {fields.map((field) => (
            <ListingChattelField
              key={field.name}
              name={field.name}
              label={field.label}
              checked={!!values?.[`has_${field.name}`]}
              count={values?.[`${field.name}_count`]}
              noCount={field.noCount}
              onCheckboxChange={handleCheckboxChange}
              onCountChange={handleCountChange}
            />
          ))}
        </div>
        <Body
          {...s('otherChattelsHeading')}
          is='h3'
          dark
          italic={false}
          bold={true}
        >
          Other chattels
        </Body>
        <TextArea
          placeholder='Other chattels'
          defaultValue={values?.other || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleOtherChange(e.target.value || '')
          }
        />
      </PaddingBox>
    </form>
  );
};

export default ListingChattels;
